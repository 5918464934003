import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, Checkbox, TextField, MenuItem, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers';

import HelpToolTip from '../../../components/HelpToolTip';
import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';
import DeterminationHelpText from '../../../config/HPTM/DeterminationHelpText.json'

const formId = 5

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: {} }
    );

    useEffect(() => {
        const initializeForm = async () => {
            if (props.editForm) {
                setForm(JSON.parse(JSON.stringify(props.editForm)))
            }
            setLoading(false)
        }
        initializeForm()
    }, [props.editForm])

    useEffect(() => {
        if (loading) return
        form.classification = form.data.updatedClassification || form.data.classification
        setForm(form)
    }, [form.data.classification, form.data.updatedClassification])

   
    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({ pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch (err) {
            console.log(err)
            context.toastError('Form could not be saved')
            Sentry.captureException(err);
        }
    }


    return (
        <div id="assessmentForm">
            <form onSubmit={handleSave}>
                <Paper className="paper">
                    <div className="flex-row">
                        <div className="flex-col">
                            <div>
                                Determination:
                            </div>
                            <FormControl component="fieldset">
                                <RadioGroup row>
                                    <FormControlLabel label="Self Harm Only" control={<Radio required />} checked={form.data.classification === 'Self Harm Only'} type="radio" required onChange={() => { form.data.classification = 'Self Harm Only'; setForm(form) }} />
                                    <FormControlLabel label="Unfounded" control={<Radio required />} checked={form.data.classification === 'Unfounded'} type="radio" required onChange={() => { form.data.classification = 'Unfounded'; setForm(form) }} />
                                    <FormControlLabel label="Low Level of Concern" control={<Radio required />} checked={form.data.classification === 'Low Level of Concern'} type="radio" required onChange={() => { form.data.classification = 'Low Level of Concern'; setForm(form) }} />
                                    <FormControlLabel label="Medium Level of Concern" control={<Radio required />} checked={form.data.classification === 'Medium Level of Concern'} type="radio" required onChange={() => { form.data.classification = 'Medium Level of Concern'; setForm(form) }} />
                                    <FormControlLabel label="High Level of Concern" control={<Radio required />} checked={form.data.classification === 'High Level of Concern'} type="radio" required onChange={() => { form.data.classification = 'High Level of Concern'; setForm(form) }} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    {DeterminationHelpText[form.data.classification] ?
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: DeterminationHelpText[form.data.classification] }}></span>
                        </div>
                        : null}
                </Paper>

                <div>
                    <Button variant="outlined" type="submit">
                        <Icon className="fa fa-save" />Save
                    </Button>
                </div>
            </form>
        </div>
    )
}