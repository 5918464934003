import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment'
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, MenuItem} from '@mui/material';
import Dialog from '@mui/material//Dialog';
import DialogTitle from '@mui/material//DialogTitle';

import Default from './Default';
import HPTM from './HPTM';
import Community from './Community'

import OtherDropDown from '../../../components/OtherDropDown';

import threatAssessmentService from '../../../services/threatAssessment';
import institutionService from '../../../services/institution'
import AppContext from '../../../context/app'

export default function Create(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams();

  const [institution, setInstitution] = useState(null)
  const [building, setBuilding] = useState(null)
  const [institutions, setInstitutions] = useState([])
  const [buildings, setBuildings] = useState([])
  const [assessmentTypes, setAssessmentTypes] = useState([])
  const [allAssessmentModels, setAllAssessmentModels] = useState([])
  const [assessmentModels, setAssessmentModels] = useState([])
  const [assessmentModel, setAssessmentModel] = useState(null)
  const [openExistingDialog, setOpenExistingDialog] = useState(false)
  const [similarAssessments, setSimilarAssessments] = useState([])
  const [assessment, setAssessment] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { assigned: [], dateOfBirth: moment().format(), threatMadeDate: moment().format(), isValid: false, additionalParticipants: [], data: {} }
  );

  const handleAsset = (assetId) => {
    new threatAssessmentService().downloadAsset(+assetId)
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        let institutions = await new institutionService().getInstitutions()
        setInstitutions(institutions)
        
        if (threatAssessmentId){
          let loadedAssessment = await new threatAssessmentService().get(threatAssessmentId)
  
          if (loadedAssessment.findings) {
            loadedAssessment.findings.forEach( (finding) => {
              let threatFinding = loadedAssessment.findings.find( (f) => finding.informationSource === f.informationSource)
              if (threatFinding) {
                finding.reviewed = threatFinding.reviewed
                finding.comments = threatFinding.comments
              }
            })
          }
          loadedAssessment.data = loadedAssessment.data || {}
          setAssessment(loadedAssessment)
          setInstitution(institutions.find(i => i.institutionId === loadedAssessment.institutionId))
          let [institutionBuildings, loadedAssessmentModel] = await Promise.all([
            new institutionService().getInstitutionBuildings(loadedAssessment.institutionId),
            new threatAssessmentService().getAssessmentModelByAssessmentId(loadedAssessment.threatAssessmentId)
          ])
          setBuilding(institutionBuildings.find(b => b.institutionBuildingId === loadedAssessment.institutionBuildingId))
          setAssessmentModel(loadedAssessmentModel)
        }
        else {
          if (institutions.length === 1 )
            setInstitution(institutions[0])
        }
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }
    loadData()
  },[])

  useEffect(() => {
    const loadBuildings = async () => {
      try {
        if (institution) {
          let institutionBuildings = await new institutionService().getInstitutionBuildings(institution.institutionId)
          if (institutionBuildings.length === 1 ) {
            setBuilding(institutionBuildings[0])
            assessment.institutionBuildingId = institutionBuildings[0].institutionBuildingId
            setAssessment(assessment)
          }
          setBuildings(institutionBuildings)
        }
      }
      catch(err){
        console.log(err)
        context.toastError('Loading buildings')
        Sentry.captureException(err);
      }
    }
    loadBuildings()
  }, [institution])

  useEffect(() => {
    const resetAssessment = async () => {
      try {
        if (!threatAssessmentId && building && building.institutionBuildingId) {
          //reset if building changes
          assessment.typeId = null
          assessment.threatAssessmentModelId = null
        }
      }
      catch(err) {
        console.log(err)
        Sentry.captureException(err)
      }
    }
    resetAssessment()
  }, [building])

  useEffect(() => {
    const loadBuildingInfo = async () => {
      try {
        if (!threatAssessmentId && building && building.institutionBuildingId) {
          //reset if building changes
          assessment.typeId = null
          assessment.threatAssessmentModelId = null

          let [assessmentTypes, assessmentModels] = await Promise.all([ 
            new threatAssessmentService().getAssessmentTypes(building.institutionBuildingId),
            new threatAssessmentService().getAssessmentModels(building.institutionBuildingId)
          ])       
          setAssessmentTypes(assessmentTypes)
          setAllAssessmentModels(assessmentModels)

          //if there is only one for the school, pick it
          if (assessmentTypes.length === 1 ){
            assessment.typeId = assessmentTypes[0].threatAssessmentTypeId
            setAssessment(assessment)
          }
        }
      }
      catch(err){
        console.log(err)
        context.toastError('Loading building info')
        Sentry.captureException(err);
      }
    }
    loadBuildingInfo()
  }, [building])

  useEffect( () => {
    setAssessmentModel()
    let assessmentModelsOptions = allAssessmentModels.filter( am => am.threatAssessmentTypeId === assessment.typeId)
    setAssessmentModels(assessmentModelsOptions)

    //if there is only one model, auto-select
    if (assessmentModelsOptions.length === 1){
      assessment.threatAssessmentModelId = assessmentModelsOptions[0].threatAssessmentModelId
      setAssessment(assessment)
    }
  },[assessment.typeId])

  useEffect( () => {
    setAssessmentModel(assessmentModels.find(  at => at.threatAssessmentModelId === assessment.threatAssessmentModelId) )
  },[assessment.threatAssessmentModelId])

  useEffect( () => {
    if (assessmentModel && !threatAssessmentId) {
      assessment.assigned = assessmentModel.interviewTypes.filter( it => !it.config.autoAssignTo && !it.config.notAssignable ).map( (it) => { return { role: it.role, userId: it.config.defaultAssignTo === 'creator' ? context.user.userId : null } })
      setAssessment(assessment)
    }
  },[assessmentModel])

  //create the threat Assessment
  const handleCreate = async (event, ignoreSimilar) => {
    event.preventDefault();

    //manually check valid dates
    if (!assessment.isValid)
        return;

    try {     
      let assessmentFindings = assessment.assessmentFindings ? assessment.assessmentFindings.filter( af => af.reviewed || af.comments ) : []
      let threatAssessment = {threatAssessmentId: assessment.threatAssessmentId, institutionBuildingId: assessment.institutionBuildingId, threatAssessmentModelId: assessment.threatAssessmentModelId, assessedFirstName: assessment.assessedFirstName, assessedLastName: assessment.assessedLastName, dateOfBirth: moment(assessment.dateOfBirth).format('YYYY-MM-DD'), subjectId: assessment.subjectId, grade: assessment.grade, gender: assessment.gender, race: assessment.race, requestorTitle: assessment.requestorTitle, reason: assessment.reason, comments: assessment.comments, 
        reportedByName: assessment.reportedByName, reportReceivedBy: assessment.reportReceivedBy, threatMadeDate: !assessmentModel.askExtraInfo || assessment.threatMadeDateUnknown || !assessment.threatMadeDate ? null : moment(assessment.threatMadeDate).format() ,threatPersonType: assessment.threatPersonType, isCurrentThreatPersonType: +assessment.isCurrentThreatPersonType,
        schoolProgram: assessment.schoolProgram,emergencyContactName: assessment.emergencyContactName,emergencyContactRelationship: assessment.emergencyContactRelationship,
        assessedHomeAddress: assessment.assessedHomeAddress,assessedPhone: assessment.assessedPhone,threatLocation: assessment.threatLocation,
        assessmentFindings, additionalParticipants: assessment.additionalParticipants, inquirers: assessment.assigned.filter( i => i.userId), initialClassification: assessment.initialClassification, data: Object.keys(assessment.data).length ? assessment.data : null
      };

      if (!assessment.threatAssessmentId) {
        if (ignoreSimilar){
          let threatAssessmentId = await new threatAssessmentService().create(threatAssessment)
          props.history.push({pathname: `/assessment/${threatAssessmentId}` }); 
          return;
        }

        //check similar
        let similarAssessments = await new threatAssessmentService().getSimilar(threatAssessment)
        if (similarAssessments.length){
          setSimilarAssessments(similarAssessments)
          setOpenExistingDialog(true)
        } else {
          assessment.threatAssessmentId = await new threatAssessmentService().create(threatAssessment)
          props.history.push({pathname: `/assessment/${assessment.threatAssessmentId}` });  
          context.toastSuccess('Assessment Saved')
        }
      }
      else {
        await new threatAssessmentService().edit(threatAssessment)
        props.history.push({pathname: `/assessment/${assessment.threatAssessmentId}` });  
        context.toastSuccess('Assessment Saved')
      }
    }
    catch(err){
      console.log(err)
      context.toastError('Assessment could not save')
      Sentry.captureException(err);
    }
  }

  return (
    <div id='threatAssessmentCreate'>
        <Dialog open={openExistingDialog} aria-labelledby="simple-dialog-title">
          <DialogTitle id="dialog-title">Existing Assessments</DialogTitle>
            <div className="dialog">
              <div className="dialog-text">
                  We found the following similar cases.
              </div>
              <div className="case-list">
                <ul className="case-links">
                  { similarAssessments.map( (similarAssessment, index) => {
                    const similarAssignmentView = `${similarAssessment.assessedFirstName} ${similarAssessment.assessedLastName} (${moment(similarAssessment.createDate).format('MM/DD/YYYY')}) ${similarAssessment.resolutionClassification ?? ''}`
                    return(
                      <li key={index}>
                        <div className="flex-row">
                          <div className="flex-col">
                            { similarAssessment.hasAccess ?
                              <a target="_blank" href={`/assessment/detail/${similarAssessment.threatAssessmentId}`}>{similarAssignmentView} </a>
                            : 
                              <span>{similarAssignmentView}</span>
                            }
                          </div>
                          <div className="flex-col">
                            { similarAssessment.pdfThreatAssessmentAssetId ?
                              <span className="similar-pdf"><i className="clickable fa fa-download" onClick={() => handleAsset(similarAssessment.pdfThreatAssessmentAssetId) } ></i></span>
                            : null }
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div>
                <Button variant="outlined" onClick={(e) => handleCreate(e, true)}>
                  <Icon className="fa fa-save" />Save
                </Button>
              </div>
            </div>
        </Dialog>

        <div>
          <form onSubmit={handleCreate}>
              { !threatAssessmentId ?
              <Paper className="paper">
                  <div className="flex-row">
                  { institutions.length > 1 ? 
                    <div className="flex-col institutionId">
                      <TextField fullWidth label="Institutions" select value={assessment.institutionId || ''} required onChange={(e) => { assessment.institutionId = e.target.value; setAssessment(assessment); setInstitution(institutions.find( i => i.institutionId === assessment.institutionId ) );}}>           
                        { institutions.map( (institution, index) => {
                            return(
                              <MenuItem key={index} value={institution.institutionId}>
                                {institution.name}
                              </MenuItem>
                            )
                          }) 
                        }
                      </TextField>
                    </div>
                  : null }

                  { buildings.length > 1 ? 
                    <div className="flex-col institutionBuildingId">
                      <TextField  fullWidth label="Building" select value={assessment.institutionBuildingId || ''} required onChange={(e) => { assessment.institutionBuildingId = e.target.value; setAssessment(assessment); setBuilding(buildings.find( b => b.institutionBuildingId === assessment.institutionBuildingId ))}}>           
                        { buildings.map( (building, index) => {
                            return(
                              <MenuItem key={index} value={building.institutionBuildingId}>
                                {building.buildingName}
                              </MenuItem>
                            )
                          }) 
                        }
                      </TextField>
                    </div>
                  : null }

                  <div className="flex-col requestorTitle">
                    <OtherDropDown fullWidth label="Your Position" value={assessment.requestorTitle || ''} required onChange={(e) => { assessment.requestorTitle = e.target.value; setAssessment(assessment)}} >
                      <MenuItem value="Administrator">Administrator</MenuItem>  
                      <MenuItem value="Faculty">Faculty</MenuItem>
                      <MenuItem value="Police">Police</MenuItem>
                      <MenuItem value="Staff">Staff</MenuItem>
                    </OtherDropDown>
                  </div>

                  <div className="flex-col threatassessmentTypeId">
                    <TextField select required label="Case Type" disabled={!building?.institutionBuildingId} value={assessment.typeId || ''} onChange={(e) => { assessment.typeId = e.target.value; setAssessment(assessment); }}>
                        { assessmentTypes.map( (assessmentType, index) => {
                            return(
                              <MenuItem key={index} value={assessmentType.threatAssessmentTypeId}>{assessmentType.name}</MenuItem>
                            )
                          }) 
                        }
                      </TextField>
                  </div>
                  
                  {(assessment.typeId && assessmentModels.length > 1)?
                  <div className="flex-col threatassessmentModelId">
                    <TextField select required label="Assessment Model" disabled={!assessment.typeId} value={assessment.threatAssessmentModelId || ''} onChange={(e) => { assessment.threatAssessmentModelId = e.target.value; setAssessment(assessment); }}>
                        { assessmentModels.map( (assessmentModel, index) => {
                            return(
                              <MenuItem key={index} value={assessmentModel.threatAssessmentModelId}>{assessmentModel.name}</MenuItem>
                            )
                          }) 
                        }
                      </TextField>
                  </div>
                  : null }
                </div>
              </Paper>
            : null }

            { assessment.threatAssessmentModelId && institution && building && assessmentModel ?
              <section>
                { !assessmentModel.config.createForm ?
                  <Default assessment={assessment} assessmentModel={assessmentModel} institution={institution} building={building} onChange={(a) => setAssessment(a)} />
                : null }

                 { assessmentModel.config.createForm === 'HPTM' ?
                  <HPTM assessment={assessment} assessmentModel={assessmentModel} institution={institution} building={building} onChange={(a) => setAssessment(a)} />
                : null }

                { assessmentModel.config.createForm === 'Community' ?
                  <Community assessment={assessment} assessmentModel={assessmentModel} institution={institution} building={building} onChange={(a) => setAssessment(a)} />
                : null }
                </section>
            : null }
             
            { assessment.threatAssessmentModelId && assessmentModel ?
              <div>
                <Button variant="outlined" type="submit">
                  <Icon className="fa fa-save" />Save
                </Button>
              </div>
            : null }
          </form>
        </div>
      </div>
  )
}