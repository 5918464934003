import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import formService from '../../../services/assessment/form/form';

import HPTM from './HPTM'
import HPTMInitial from './HPTMInitial'
import HPTMDetermination from './HPTMDetermination'
import HPTMFollowUps from './HPTMFollowUps'
import HPTMLiteDetermination from './HPTMLiteDetermination'

import CommunityDetermination from './CommunityDetermination'
import CommunityFollowUps from './CommunityFollowUps'
import CommunityReferral from './CommunityReferral'

Form.propTypes = {
    threatAssessmentId: PropTypes.number,
    formAnswerId: PropTypes.number,
    editForm: PropTypes.object
};

export default function Form(props) {
    const { threatAssessmentId, path, formAnswerId } = useParams();
    const [ editForm, setEditForm ] = useState()
    const [ formPath, setFormPath ] = useState()

    useEffect(() => {
        const loadForm = async () => {
            if (formAnswerId){
                let editForm = await new formService().getAnswer(formAnswerId)
                setEditForm(editForm)
                setFormPath(editForm.path)
            }
            else
                setFormPath(path)
        }
        loadForm()
    }, [formAnswerId, path])

    return (
        <div id="assessmentForm">
            { formPath === 'HPTM' ? <HPTM {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
            { formPath === 'HPTMInitial' ? <HPTMInitial {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
            { formPath === 'HPTMDetermination' ? <HPTMDetermination  {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
            { formPath === 'HPTMFollowUps' ? <HPTMFollowUps  {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
            { formPath === 'HPTMLiteDetermination' ? <HPTMLiteDetermination  {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }

            { formPath === 'CommunityDetermination' ? <CommunityDetermination  {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
            { formPath === 'CommunityFollowUps' ? <CommunityFollowUps  {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
            { formPath === 'CommunityReferral' ? <CommunityReferral  {...props} threatAssessmentId={threatAssessmentId} formAnswerId={formAnswerId} editForm={editForm} /> : null }
        </div>
    )
}