import React, { useEffect, useReducer, useContext, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, FormControl, RadioGroup, Radio, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import OtherCheckbox from '../../../components/OtherCheckbox';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import threatAssessmentService from '../../../services/threatAssessment';
import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';

const formId = 7

const classificationMonitoringEndDateMap = {
    "Medium Level of Concern": 90,
    "High Level of Concern": 180
}

let actionPlanOptions= [
    'NCIC/Background Check',
    'Risk Protection Order Investigation',
    'Vulnerability Assessment Performed',
    'Criminal Investigation by Law Enforcement',
    'Involuntary Mental Health Evaluation',
    'Temporary Order of Protection Issued',
    'Arrested and Charged with Crime',
    'Threat Assessment ARGO Monitoring'
]

export default function Form(props) {
    const context = useContext(AppContext)
    const [resolution, setResolution] = useState({})
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: { meetingDate: moment(), actionPlan: []} }
    );
    
    useEffect(() => {
        const loadAssessment = async () => {
            let loadedAssessment = await new threatAssessmentService().get(props.threatAssessmentId)
            if (!props.editForm && classificationMonitoringEndDateMap[loadedAssessment.resolutionClassification])
                form.data.monitoringEndDate = moment().add(classificationMonitoringEndDateMap[loadedAssessment.resolutionClassification], 'days')
            setForm(props.editForm)
            if (loadedAssessment.resolution){
                if (loadedAssessment.resolution.resolutionFollowUpDate)
                    loadedAssessment.resolution.resolutionFollowUpDate = moment(loadedAssessment.resolution.resolutionFollowUpDate)
                setResolution(loadedAssessment.resolution)
            }
        }
        loadAssessment()
    },[])

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)

            if (resolution.resolutionFollowUpDate){
                let resolutionUpdate = { threatAssessmentId: form.threatAssessmentId, resolutionFollowUpDate: resolution?.resolutionFollowUpDate? resolution.resolutionFollowUpDate.format('YYYY-MM-DD') : null };
                await new threatAssessmentService().resolve(resolutionUpdate)
            }

            props.history.push({pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch(err){
        console.log(err)
        context.toastError('Form could not be saved')
        Sentry.captureException(err);
        }
    }

    let actionPlanOtherComponent = (form) => {
        let otherIndex = form.data.actionPlan.findIndex( ap => !actionPlanOptions.includes(ap))
        let otherValue = form.data.actionPlan[otherIndex]
        return(
          <OtherCheckbox maxLength="100" value={otherValue || '' } checkedOnChange={(e) => { if (e.target.checked) form.data.actionPlan.push(''); else { form.data.actionPlan.splice(otherIndex,1)} setForm(form)}} onChange={(e) => { form.data.actionPlan[otherIndex] = e.target.value; setForm(form) }} ></OtherCheckbox>
        )
      }

    return (
    <div id="assessmentForm">
        <form onSubmit={handleSave}>
            <Paper className="paper section">
                {props.editForm?.timeStamp ? 
                <div className="timeStamp">Submitted {moment(props.editForm?.timeStamp).format('MM/DD/YYYY')}</div>                
                : null}

                { !props.editForm ? <div>When the Behavioral Threat Assessment Team (BTAT) places a subject on a Threat Management Plan (TMP), the BTAT should meet monthly and assess the efficacy of the TMP. If the TMP is having the desired results, then no modifications to the TMP are necessary. The BTAT may in its discretion eliminate or modify TMP requirements. However, if desired results are not being obtained through the TMP, then the BTAT should consider modifying or adding requirements to the TMP. If at the end of the minimum monitoring period the BTAT determines that it is appropriate to terminate the TMP, then case should be closed.</div> : null }
                <div className="section flex-row">
                    <div className="flex-col">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Meeting Date" value={form.data?.meetingDate || null} onChange={(e) => {if (!e) { return }; form.data.meetingDate = e; setForm(form)}}/>
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker disablePast={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date Mandatory Monitoring Period Ends" value={form.data?.monitoringEndDate || null} onChange={(e) => {if (!e) { return }; form.data.monitoringEndDate = e; setForm(form)}}/>
                        </LocalizationProvider>
                    </div>
                </div>




                <div className="section">
                    Actions Taken in the TMP
                    <FormGroup>
                    { actionPlanOptions.map( (actionPlanOption, index) => {
                        return (
                        <FormControlLabel className='actionPlanOption' key={index} label={actionPlanOption} control={<Checkbox value={actionPlanOption} checked={!!form.data.actionPlan.find( a => a === actionPlanOption) } onChange={(e) => { if (e.target.checked) { form.data.actionPlan.push(actionPlanOption) } else { form.data.actionPlan.splice(form.data.actionPlan.findIndex( a => a === actionPlanOption), 1) } setForm(form) }}/>} />
                        )
                    })}
                    </FormGroup>                    
                    <span className="flex-row">
                    { actionPlanOtherComponent(form) }
                    </span>
                </div>

                <div className="flex-row section">
                    <div className="flex-col">
                        <TextField label='Has other entities (i.e. mental health, social services, parole, etc.) provided any input to the CBTAT regarding this TMP? (If yes, what?)'  inputProps={{ maxLength: 200}} minRows={4} multiline type="text" value={form.data?.otherEntityHelpModifyTMPComment || ''} onChange={(e) => { form.data.otherEntityHelpModifyTMPComment = e.target.value; setForm(form)}}/>
                    </div>
                </div>


                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='After review, is the BTAT modifying the TMP? (If yes, how and if not, why not?) ' minRows={4} required multiline type="text" value={form.data?.modifyTMPComment || ''} onChange={(e) => { form.data.modifyTMPComment = e.target.value; setForm(form)}}/>
                    </div>
                </div>
                 

                <div>If the mandatory monitoring period is expiring, will the TMP be:</div>
                <FormControl component="fieldset">
                    <RadioGroup row>
                        <FormControlLabel label="Terminated" control={<Radio required />} checked={ form.data?.monitoringPeriodStatus === 'Terminated' } type="radio" required onChange={() => { form.data.monitoringPeriodStatus = 'Terminated'; setForm(form) } }/>
                        <FormControlLabel label="Extended" control={<Radio required />} checked={  form.data?.monitoringPeriodStatus === 'Extended' } type="radio" required onChange={() => { form.data.monitoringPeriodStatus = 'Extended'; setForm(form) } }/>
                        <FormControlLabel label="Has Not Expired" control={<Radio required />} checked={  !form.data?.monitoringPeriodStatus } type="radio" required onChange={() => { form.data.monitoringPeriodStatus = null; setForm(form) } }/>
                    </RadioGroup>
                </FormControl>

                <div className="flex-row">
                    <div className="flex-col">
                        <TextField label='Supplemental Notes' minRows={4} inputProps={{ maxLength: 8000}} multiline type="text" value={form.data?.notes || ''} onChange={(e) => { form.data.notes = e.target.value; setForm(form)}}/>
                    </div>
                </div>

                { props.showFollowUpDate ?
                 <div className='flex-row section'>
                    <div className='flex-col'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker disablePast={true} label="Remind Me to Follow Up" value={resolution?.resolutionFollowUpDate || null} onChange={(e) => { resolution.resolutionFollowUpDate = e; setResolution({...resolution}) }} renderInput={(props) => <TextField {...props} />}/>
                    </LocalizationProvider>
                    </div>
                </div>   
                : null } 
                               
            </Paper>
            <div>
                <Button variant="outlined" type="submit">
                    <Icon className="fa fa-save" /> { props.editForm ? 'Edit' : 'Save' }
                </Button>
            </div>
        </form>
    </div>
    )
}