import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../context/app'
import { Paper, Icon, Button } from '@mui/material';

import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import moment from 'moment'

const iconAssessmentTypeMap = {
  "Threat Assessment": 'fa-exclamation-triangle',
  "Suicide/Self Harm Assessment": 'fa-tint',
  "Bully Intervention Assessment": 'fa-school',
  "Workplace Violence Employee Assessment": 'fa-exclamation-triangle',
  "Other": 'fa-eye',
  "Title IX": 'fa-times-circle'
}

export default function List(props) {
  const context = useContext(AppContext)
  const [showInstitutionName, setInstitutionName] = useState()

  useEffect(() => {
    const initialize = async () => {
      window.scroll(0,0)
      try {
        //if many institutionNames show the name
        setInstitutionName([...new Set(props.assessments.map(a => a.institutionName))].length > 1)
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }
    initialize()
  },[])

  return (
    <div id='threatAssessments'>
        { props.assessments.length ?
          <div className="threatAssessments">
            {
                props.assessments.map( (threatAssessment, index) => {
                    return (
                      <Link to={`/assessment/${threatAssessment.threatAssessmentId}`} key={index}>
                        <div className="flex-row">
                        <Paper className="flex-row threatAssessment" elevation={1}>
                            <div className="flex-col threatAssessmentInfo">
                                <div className="threatAssessmentHeadline flex-row" >
                                  <div className="flex-col">
                                    <span className="threatAssessmentName">
                                      <i title={threatAssessment.typeName} className={`assessmentTypeIcon fa ${iconAssessmentTypeMap[threatAssessment.typeName]}`}></i>
                                      {threatAssessment.assessedFirstName}&nbsp;{threatAssessment.assessedLastName}
                                    </span>
                                  </div>


                                  <div className="threatAssessmentTypeName flex-col">{threatAssessment.typeName}</div>
                                  { showInstitutionName ?
                                    <div className="threatAssessmentInstitutionName flex-col">{threatAssessment.institutionName}</div>
                                  : null }
                                  <div className="threatAssessmentCreateDate flex-col">Created: {moment.utc(threatAssessment.createDate).local().format('M/D/YYYY')}</div>                                  
                                </div>
                              
                                { threatAssessment.resolutionClassification || (threatAssessment.threatLevelClassification && threatAssessment.threatLevelClassification !== 'NA') ?
                                  <div className="flex-row" >
                                    <div className="threatAssessmentClassification flex-col">{ threatAssessment.resolutionClassification || threatAssessment.threatLevelClassification }</div>
                                  </div>
                                : null }
                                <div className="threatAssessmentInfoComments flex-row" >
                                  <div className="threatAssessmentComments flex-col">{threatAssessment.comments}</div>
                                </div>                                       
                              </div>
                              <div className="flex-col open-button">
                                <Button variant="outlined">
                                  <Icon className="fa fa-edit" />Open
                                </Button>
                              </div>
                        </Paper>
                        </div>
                      </Link>
                    );
                })
            }
          </div>
        :
        <div className="no-archive">
        <Paper elevation={1}>
          <div className="no-achive-label">No Cases</div>
        </Paper>
      </div>
        }
    </div>
  )
}