import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper } from '@mui/material';
import DropZone from '../../components/DropZone';
import FileViewer from '../../components/FileViewer'

import threatAssessmentService from '../../services/threatAssessment';
import AppContext from '../../context/app'

export default function Detail(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()

  const [assessmentAssets, setAssessmentAssets] = useState([])
  const [files, setFiles] = useState([])
  const [fileViewerOpen, setFileViewerOpen] = useState(false)
  const [fileViewerAssetIndex, setFileViewerAssetIndex ] = useState()

  const bindAssets = async () => {
    let assetNames = await new threatAssessmentService().getAssetNames(threatAssessmentId, 'document')
    const imageExt = ['.png','.gif','.jpg','.jpeg']
    let endsWithAny = (suffixes, string) => {
      return suffixes.some(function (suffix) {
          return string.toLowerCase().endsWith(suffix);
      });
    }
    assetNames.forEach( (a) => {
      a.isImage = endsWithAny(imageExt, a.fileName)
    })
    setAssessmentAssets(assetNames)
  }


  useEffect(() => {
    const initializeAssets = async () => {
      window.scroll(0,0)
      try {
        bindAssets()
      }
      catch(err){
        console.log(err)
        Sentry.captureException(err)
      }
    }
    initializeAssets()
  }, [])


const handleUploadFile = async (files) => {
    if (!files.length)
      return

    try {
      async function uploadFiles() {
        for (const file of files) {
          let data = new FormData()
          data.append('file', file)
          await new threatAssessmentService().uploadFile(+threatAssessmentId, data, 'document')
        }
      }

      await uploadFiles.bind(this)()

      //reload the images
      bindAssets()
      setFiles([])
      context.toastSuccess('File uploaded')
    }
    catch(err){
      console.log(err)
      context.toastError('File could not be uploaded')
      setFiles([])
    }
  }


  const handleDeleteImage = async (assetId) => {
      try{
        await new threatAssessmentService().deleteAsset(assetId)
        //reload the images
        let assetNames = await new threatAssessmentService().getAssetNames(+threatAssessmentId, 'document')
        //bind them
        setAssessmentAssets(assetNames)
        context.toastSuccess('File deleted')
    }
    catch(err){
        console.log(err)
        context.toastError('File could not be deleted')
        Sentry.captureException(err);
    }
  }

  const getImageSource = (file) => {
    console.log(file.threatAssessmentAssetId)
    return `/api/threatAssessment/downloadAsset?assetId=${file.threatAssessmentAssetId}`
  }
  

  return (
    <div>
      <FileViewer open={fileViewerOpen} onClose={e => setFileViewerOpen(false)} files={assessmentAssets} index={fileViewerAssetIndex} onDownload={ (e) => new threatAssessmentService().downloadAsset(assessmentAssets[fileViewerAssetIndex].threatAssessmentAssetId)} imageSource={getImageSource} ></FileViewer>


      <div id='threatAssessment'>
        { assessmentAssets.length ?
            <div className="files">
                <Paper className="paper">
                <h2>Files:</h2>
                  { assessmentAssets.length ?
                      <div className="flex-row assets">
                      { assessmentAssets.map( (fileName, index) => {
                          return(
                          <div className="flex-col uploaded-file-container" key={index}>
                              { fileName.isImage ?
                              <img  
                                  src={getImageSource(fileName)}
                                  className="uploaded-file"
                                  data-fileName={fileName.fileName}
                                  alt={ fileName.notes || fileName.fileName  }
                                  title={fileName.notes || fileName.fileName }
                                  onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                              />
                              :
                              <span 
                                  className="fa fa-file uploaded-file"
                                  data-fileName={fileName.fileName}
                                  alt={ fileName.notes || fileName.fileName  }
                                  title={fileName.notes || fileName.fileName }
                                  onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                              />
                              }

                              <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteImage(fileName.threatAssessmentAssetId) }}></i>
                              <div className="uploaded-file-caption">
                                  {fileName.fileName.substring(0,9)}
                              </div>
                          </div>
                          )
                          }) 
                      }
                      </div>
                  : null }
                </Paper>
            </div>
      : null }

        <div className="dropzonearea">                  
            <DropZone
                multiple={false}
                maxSize={30000000}
                onDrop={(fileObjs) => { let newFiles = files.concat(fileObjs); setFiles(newFiles);  handleUploadFile(newFiles)}}
            />
        </div>
      </div>
      </div>
  )
}