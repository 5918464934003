import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material//Dialog';
import DialogTitle from '@mui/material//DialogTitle';
import { Icon, Button, TextField, Autocomplete } from '@mui/material';
import * as Sentry from '@sentry/browser';
import AppContext from '../context/app'
import threatAssessmentService from '../services/threatAssessment';
import userService from '../services/user';


export default function ShareDialog(props) {
    const context = useContext(AppContext)
  
    const [users, setUsers] = useState([])
    const [toUser, setToUser] = useState(null)
  
    useEffect(() => {
        const loadUsers = async () => {
            try {
                let threatAssessment = await new threatAssessmentService().get(+props.assessment.threatAssessmentId)
                let buildingUsers = await new userService().getInstitutionBuildingUsers(threatAssessment.institutionBuildingId)
                //send to those that have at least some escalated permissions
                buildingUsers = buildingUsers.filter( bu => bu.threatTeam || bu.viewSelfHarm || bu.viewBully || bu.viewAllThreats)
                setUsers(buildingUsers)
            }
            catch(err) {
                console.log(err)
                context.toastError('Loading')
                Sentry.captureException(err)
            }
        }
        loadUsers()
    }, [props.assessment.threatAssessmentId])

    const handleShareCase = async (e) => {
        e.preventDefault();
  
          try {
              await new threatAssessmentService().share(+props.assessment.threatAssessmentId, toUser.id)
              setToUser(null)
              props.closeDialog()
              context.toastSuccess('Assessment shared')
          }
          catch(err){
              console.log(err)
              context.toastError('Assessment could not be shared')
          }
    }

    return (
        <Dialog open={props.open} onClose={(e) => props.closeDialog()} aria-labelledby="simple-dialog-title">
            <DialogTitle id="dialog-title">Share Case with:</DialogTitle>
            <div className="share-dialog">
                <form onSubmit={handleShareCase}>
                    <div className="share-dialog-email">
                        <Autocomplete
                            options={users.map( u => { return {label: u.firstName ? `${u.firstName} ${u.lastName}` : u.email, id: u.userId } })}
                            value={toUser?.label || ''} 
                            onChange={(e, newValue) => { setToUser(newValue)} }
                            sx={{ width: 300 }}
                            required
                            renderInput={(params) => <TextField {...params} required label="Share With" />}
                            />                    
                    </div>

                    <div className="share-button-container">
                        <Button variant="outlined" type="submit">
                            <Icon className="fa fa-envelope" />Share
                        </Button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}