import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function MyDropzone({ onDrop, maxSize = 30000000, multiple = true, acceptedFiles = '*' }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize,
    multiple,
    acceptedFiles,
  });

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 3,
        textAlign: 'center',
        border: '2px dashed #1976d2',
        borderRadius: 2,
        bgcolor: isDragActive ? 'action.hover' : 'background.paper',
        cursor: 'pointer',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <CloudUploadIcon sx={{ mb: 1, color: 'black' }} fontSize="large" />
      <Typography variant="body1" color="textSecondary">
        {isDragActive ? 'Drop the file here...' : "Drag 'n' drop a file here, or click to select one"}
      </Typography>
    </Paper>
  );
}

export default MyDropzone;