import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../context/app'
import { Paper, Icon, Button, TextField, MenuItem, FormControl } from '@mui/material';
import Loader from '../../../components/Loader'

import * as Sentry from '@sentry/browser';
import threatAssessmentService from '../../../services/threatAssessment';
import AssessmentList from '../../../components/AssessmentList'

export default function Archive() {
  const context = useContext(AppContext)
  const [assessments, setAssessments] = useState([])
  const [loading, setLoading] = useState(true)
  const [yearCounts, setYearCounts] = useState([])
  const [searchName, setSearchName] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    const fetchData = async () => {
      window.scroll(0,0)
      try {
        let [assessments, yearCounts] = await Promise.all([
          new threatAssessmentService().getAll(false),
          new threatAssessmentService().getYearCount(false)
        ])
        setAssessments(assessments)
        setYearCounts(yearCounts)
       
        setLoading(false)
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }

    fetchData()
  },[])

  const loadAssessments = async (e) => {
    try {
      let assessments = await new threatAssessmentService().getAll(false, year)
      //front end search
      if (searchName && searchName.length)
        assessments = assessments.filter( a => `${a.assessedFirstName} ${a.assessedLastName}`.toLowerCase().indexOf(searchName.toLowerCase()) > -1 )

      setAssessments(assessments)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  }

  useEffect(() => {
    const loadAssessmentsForYear = async () => {
      loadAssessments()
    }
    loadAssessmentsForYear()
  }, [year])

  const search = (e) => {
    e.preventDefault()
    loadAssessments()
  }

  return (
    <div id='threatAssessments'>
            <div>
                <div className="threatAssessments">
                  <div>
                    <form onSubmit={(e) => search(e)}>
                      { yearCounts.length ?
                        <Paper elevation={1} className="flex-row search-bar">
                              <div className="flex-col">
                                  <FormControl className="search-field search-field-small">
                                      <TextField select label="Year" value={year} onChange={(e) => setYear(e.target.value)} >
                                        { yearCounts.map( (yearCount, index) => {
                                          return(
                                            <MenuItem key={index} value={yearCount.year}>{yearCount.year} ({yearCount.threatCount})</MenuItem> 
                                            )
                                          })
                                        }                                
                                      </TextField>
                                  </FormControl>
                              </div>
                              <div className="flex-col">
                                <div className="flex-row search-fields">
                                  <div className="flex-col">
                                    <TextField className="search-field" maxLength="60" type="text" label="Assessment Name" value={searchName} required onChange={(e) => setSearchName(e.target.value)} />
                                  </div>
                                  <div className="flex-col">
                                    <Button className="search-button" type="submit" variant="outlined">
                                      <Icon className="fa fa-search" />Search
                                    </Button>
                                  </div>
                                </div>
                              </div>
                        </Paper>
                    : null }
                    </form>
                  </div>

                  { !loading  ?
                  <div>
                    <AssessmentList assessments={assessments} ></AssessmentList>
                  </div>
                  : <Loader/> }
                </div>
              
            </div>
            

        </div>
    )
}