import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../context/app'
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../../components/Loader'
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import moment from 'moment'
import threatAssessmentService from '../../../services/threatAssessment';

import AssessmentList from '../../../components/AssessmentList'

import Banner from '../../../components/Banner';

const DAYS_TO_WARN_TO_RESOLVE = 5

export default function List() {
  const context = useContext(AppContext)
  const [assessments, setAssessments] = useState([])
  const [loading, setLoading] = useState(true)
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    const loadAssessments = async () => {
      window.scroll(0,0)
      try {
        const assessments = await new threatAssessmentService().getAll(true)
        setAssessments(assessments)
        setLoading(false)
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }
    loadAssessments()
  },[])

  useEffect(() => {
    const checkAssessments = async () => {
      assessments.forEach( a => a.ownerAccess = a.userId === context.user.userId || context.user.viewAllThreats)
      let bannerAssessment = assessments.find( a => a.ownerAccess && a.typeName === 'Threat Assessment' && !a.resolutionClassification && !a.resoultion?.resolutionNotes && ((moment(a.createDate) <= moment().subtract(DAYS_TO_WARN_TO_RESOLVE, 'day'))) )
      //if every assignment has at least 1 interview complete, or a day has passed remind to resolve for threat assessments
      if (bannerAssessment)
        setBanner({ title: 'Warning', message:`Please create an action plan for ${bannerAssessment.assessedFirstName} ${bannerAssessment.assessedLastName}`, severity:"info", todo: { link: `/assessment/${bannerAssessment.threatAssessmentId}`, text: 'Action Plan' }})    
    }
    checkAssessments()
  },[assessments])


  return (
    <div id='threatAssessments'>
        { banner ? 
            <Banner title={banner.title} message={banner.message} severity={banner.severity} todo={banner.todo}></Banner>
        : null }

        <div className="flex-row">                  
          <div className="create-threat-button-holder">
              <Link className='create-threat-button' to={`/create`}>
                <Tooltip title="Add Case">
                  <Fab className="flex-col no-print" size="small" color="primary" aria-label="add">
                    <AddIcon /> 
                  </Fab>
                </Tooltip>
              </Link>
          </div>
        </div>

        { !loading  ?
        <div>
          <AssessmentList assessments={assessments} ></AssessmentList>
        </div>
        : <Loader/> }
    </div>
  )
}