import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import moment from 'moment'
import * as Sentry from '@sentry/browser';
import { Paper, TextField, Checkbox, Radio, MenuItem, FormGroup, RadioGroup, FormControl, FormControlLabel} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import OtherDropDown from '../../../components/OtherDropDown';
import userService from '../../../services/user'
import AppContext from '../../../context/app'

CreateDefault.propTypes = {
    assessmentModel: PropTypes.object,
    assessment: PropTypes.object,
    institution: PropTypes.object,
    building: PropTypes.object,
    onChange: PropTypes.func
};

export default function CreateDefault(props) {
  const context = useContext(AppContext)
  let [loading, setLoading] = useState(true)
  let [reviewers, setReviewers] = useState([])

  useEffect(() => {
    const initializeAssessment = async () => {
      try {
        if (!props.assessment.data) 
          props.assessment.data = {}
        
        props.assessment.data.reportReceivedDateTime = props.assessment.data.reportReceivedDateTime ? moment.utc(props.assessment.data.reportReceivedDateTime).local().format() : moment().format()
        props.assessment.directedTowardParentNotifiedDate = props.assessment.directedTowardParentNotifiedDate ? moment.utc(props.assessment.data.directedTowardParentNotifiedDate).local().format() : moment().format()

        if (!props.assessment.threatAssessmentId){       
          if (!props.assessment.data.witnesses)
            props.assessment.data.witnesses = [{}]
        }
        props.onChange(props.assessment)    
        setLoading(false)
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }
    initializeAssessment()
  }, [])

  useEffect(() => {
    const loadReviewers = async () => {
      try {      
        let buildingReviewers = await new userService().getInstitutionBuildingUsers(props.building.institutionBuildingId)
        buildingReviewers = buildingReviewers.filter( (user) => { return user.reviewThreats } )
        buildingReviewers = buildingReviewers.map( (user) => {
          user.nameText = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
          return user
        }) 
        buildingReviewers = buildingReviewers.sort( (a, b) => { return (a.lastName + a.firstName) - (b.lastName + b.firstName)  } )
        setReviewers(buildingReviewers)
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }
    loadReviewers()
  }, [props.building])

  useEffect(() => {
    const validateAssessment = async () => {
      let isValid = true
      if(!moment(props.assessment.threatMadeDate).isValid())
        isValid = false

      if(!moment(props.assessment.dateOfBirth).isValid() || !(moment(props.assessment.dateOfBirth) < moment().subtract(5, 'years')))
        isValid = false

      if(props.assessment.data.reportReceivedDateTime && !moment(props.assessment.data.reportReceivedDateTime).isValid())
        isValid = false

      if(props.assessment.data.policeReportedDate && !moment(props.assessment.data.policeReportedDate).isValid())
        isValid = false
  
      props.assessment.isValid = isValid
      props.onChange(props.assessment)
    }
    validateAssessment()
  }, [props.assessment.institutionBuildingId, props.assessment.threatMadeDate, props.assessment.dateOfBirth, props.assessment.data.reportReceivedDateTime, props.assessment.data.policeReportedDate ])

  if (loading)
    return (null)

  return (
        <div>
            <Paper className="paper">
              <h3>Form A</h3>
              <div className="flex-row ">
                <div className="flex-col">
                  Today's Date: {moment().format('MM/DD/YYYY')}
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col">
                  <div className="flex-row ">
                  {!props.assessment.threatMadeDateUnknown ? 
                      <div className="flex-col">                            
                        <LocalizationProvider dateAdapter={AdapterMoment}>                          
                          <DateTimePicker disableFuture={true} renderInput={(props) => <TextField {...props} />} label="Date/Time of Incident" value={props.assessment.threatMadeDate ? moment.utc(props.assessment.threatMadeDate).local() : null } onChange={(e) => {if (!e) { return }; props.assessment.threatMadeDate = e.format(); props.onChange(props.assessment)}}/>
                        </LocalizationProvider>
                      </div>
                    : null }
                    <div className="flex-col threatMadeDateUnknown">
                      <FormGroup>
                        <FormControlLabel control={<Checkbox checked={props.assessment.threatMadeDateUnknown} onChange={(e) => { props.assessment.threatMadeDateUnknown = e.target.checked; props.onChange(props.assessment)}} />} label="Unknown Date/Time" />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col threatLocation">
                  <OtherDropDown label="Location of Incident" value={props.assessment.threatLocation} required name="threatLocation" onChange={(e) => { props.assessment.threatLocation = e.target.value; props.onChange(props.assessment)}} >
                      <MenuItem value="School Building or Grounds">School Building or Grounds </MenuItem>
                      <MenuItem value="School Bus/Other Travel">School Bus/Other Travel </MenuItem>
                      <MenuItem value="School-Sponsored Activity">School-Sponsored Activity</MenuItem>
                      <MenuItem value="Digital communication such as text or post">Digital communication such as text or post</MenuItem>
                      <MenuItem value="Unknown">Unknown</MenuItem>
                  </OtherDropDown>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col">                            
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker renderInput={(props) => <TextField {...props} />} label="Date/Time of Report Received by SBTMT Chair" value={props.assessment.data.reportReceivedDateTime ? moment.utc(props.assessment.data.reportReceivedDateTime).local() : null} onChange={(e) => {if (!e) { return }; props.assessment.data.reportReceivedDateTime = e; props.onChange(props.assessment)} }/>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col"> 
                  <FormControlLabel control={<Checkbox checked={props.assessment.data?.isReportedToPolice || false} onChange={(e) => { props.assessment.data.isReportedToPolice = e.target.checked; props.assessment.data.policeReportedDate = moment().format(); props.onChange(props.assessment)}} />} label="This was an Imminent Threat Reported to the Police Before SBTMT?" />
                </div>
              </div>

              { props.assessment.data?.isReportedToPolice ?
                  <div className="flex-row">
                      <div className="flex-col">
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date/Time Reported" value={props.assessment.data.policeReportedDate ? moment.utc(props.assessment.data.policeReportedDate).local() : null} onChange={(e) => {if (!e) { return }; props.assessment.data.policeReportedDate = e.format(); props.onChange(props.assessment)}}/>
                          </LocalizationProvider>
                      </div>
                      <div className="flex-col">
                          <TextField label='Name of Officer Reported to' type="text" value={props.assessment.data?.policeReportedName || ''} onChange={(e) => { props.assessment.data.policeReportedName = e.target.value; props.onChange(props.assessment)}}/>
                      </div>
                      <div className="flex-col">
                          <TextField label='Police case #' maxLength="50" type="text" value={props.assessment.data?.policeCaseNumber || ''} onChange={(e) => { props.assessment.data.policeCaseNumber = e.target.value; props.onChange(props.assessment)}}/>
                      </div>
                  </div>
              : null }

              <div className="flex-row ">
                <div className="flex-col">
                  <TextField label="Name of Reporter to SBTMT" type="text" maxLength="100" value={props.assessment.reportedByName || ''} required onChange={(e) => { props.assessment.reportedByName = e.target.value; props.onChange(props.assessment)}}/>
                </div>
                <div className="flex-col">
                  <TextField label="Contact Number" type="text" maxLength="20" value={props.assessment.data.reportedByPhone || ''} required onChange={(e) => { props.assessment.data.reportedByPhone = e.target.value; props.onChange(props.assessment)}}/>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col">
                  <OtherDropDown fullWidth value={props.assessment.data.reportedByMethod}  label="Method of Receiving Information by SBTMT" required onChange={(e) => { props.assessment.data.reportedByMethod = e.target.value; props.onChange(props.assessment)}} >
                      <MenuItem value="In-person">In-person</MenuItem>
                      <MenuItem value="Phone">Phone</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="Social Media">Social Media</MenuItem>
                      <MenuItem value="Tap App Student">Tap App Student</MenuItem>
                      <MenuItem value="FortifyFL">FortifyFL</MenuItem>
                  </OtherDropDown>
                </div>
              </div>


              <div className="flex-row ">
                <div className="flex-col">
                  <OtherDropDown fullWidth value={props.assessment.reason}  label="Reporter's Affiliation to School" required onChange={(e) => { props.assessment.reason = e.target.value; props.onChange(props.assessment)}} >
                      <MenuItem value="Anonymous Communication">Anonymous Communication</MenuItem>
                      <MenuItem value="Student">Student</MenuItem>
                      <MenuItem value="Faculty/Staff">Faculty/Staff</MenuItem>
                      <MenuItem value="Parent/Family">Parent/Family</MenuItem>
                  </OtherDropDown>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col"> 
                  <FormControlLabel control={<Checkbox checked={props.assessment.data?.isReportedToSchoolAdministrator || false} onChange={(e) => { props.assessment.data.isReportedToSchoolAdministrator = e.target.checked; props.onChange(props.assessment)}} />} label="Was Reported to School Administrator?" />
                </div>
              </div>

              { props.assessment.data?.isReportedToSchoolAdministrator ?
                <div className="flex-row ">
                  <div className="flex-col">
                    <TextField label="Name of School Administrator Reported to" type="text" maxLength="100" value={props.assessment.reportReceivedBy || ''} onChange={(e) => { props.assessment.reportReceivedBy = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                </div>
              : null }
              </Paper>

              <Paper className="paper">
              <div className="flex-row ">
                  <div className="flex-col assessedFirstName-container">
                    <TextField label="First Name of Student of Concern" type="text" maxLength="25" value={props.assessment.assessedFirstName || ''} required onChange={(e) => { props.assessment.assessedFirstName = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                  <div className="flex-col assessedLastName-container">
                    <TextField label="Last Name of Student of Concern" type="text" maxLength="25" value={props.assessment.assessedLastName || ''} required onChange={(e) => { props.assessment.assessedLastName = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
              </div>           

              <div className="flex-row ">
                <div className="flex-col">
                  <TextField label="Student I.D. #" type="text" maxLength="30" value={props.assessment.subjectId || ''} onChange={(e) => { props.assessment.subjectId = e.target.value; props.onChange(props.assessment)}}/>
                </div>
                <div className="flex-col dateOfBirth">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker disableFuture={true} maxDate={moment().subtract(5, 'years')} minDate={moment().subtract(50,'years')} openTo='year' fullWidth renderInput={(props) => <TextField required {...props} />} label="Date Of Birth" value={ moment(props.assessment.dateOfBirth).local() } required onError={(e) => {props.assessment.dateOfBirthIsValid = e === null; props.onChange(props.assessment)}} onChange={(e) => { if (!e) { return }; props.assessment.dateOfBirthIsValid = e.isValid();  props.assessment.dateOfBirth = e.format(); props.onChange(props.assessment)}}/>
                    </LocalizationProvider>
                </div>
                <div className="flex-col gender">
                    <TextField select label="Identification" required value={props.assessment.gender || ''} onChange={(e) => { props.assessment.gender = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Neutral">Neutral</MenuItem>
                    </TextField>
                  </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col race">
                    <TextField select label="Race" required value={props.assessment.race || ''} onChange={(e) => { props.assessment.race = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="African American">African American</MenuItem>
                      <MenuItem value="American Indian">American Indian</MenuItem>
                      <MenuItem value="Asian">Asian</MenuItem>
                      <MenuItem value="Latin American">Latin American</MenuItem>
                      <MenuItem value="White">White</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                </div>
                <div className="flex-col">
                  <TextField label="Grade" type="number" InputProps={{inputProps: { min: 0 }}} required value={props.assessment.grade || ''} onChange={(e) => { props.assessment.grade = e.target.value; props.onChange(props.assessment)}}/>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col">
                    <TextField select label="Student Has an Existing IEP, 504, BIP or Similar Plan" value={props.assessment.data.accomidationPlanType || ''} onChange={(e) => { props.assessment.data.accomidationPlanType = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="IEP">IEP</MenuItem>
                      <MenuItem value="504">504</MenuItem>
                      <MenuItem value="BIP">BIP</MenuItem>
                      <MenuItem value="Similar Plan">Similar Plan</MenuItem>
                    </TextField>
                  </div>
              </div>
              </Paper>

              <Paper className="paper">
              <div className="flex-row ">
                <div className="flex-col">
                  <TextField select label="Incident Type" required value={props.assessment.data.incidentType || ''} onChange={(e) => { props.assessment.data.incidentType = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="Self-Harm">Self-Harm</MenuItem>
                      <MenuItem value="Harm Toward Others">Harm Toward Others</MenuItem>
                      <MenuItem value="Self-Harm and Harm Toward Others">Self-Harm and Harm Toward Others</MenuItem>
                      <MenuItem value="Unable to Determine at this Stage">Unable to Determine at this Stage</MenuItem>
                  </TextField>
                  <div>
                  (If Unable to Determine then Treat as Harm Toward Others)
                  </div>
                </div>
              </div>

              <div className="flex-row ">
                <div className="flex-col">
                 <TextField label='Who is the Threatened Harm Directed Toward?' type="text" value={props.assessment.data?.directedToward || ''} onChange={(e) => { props.assessment.data.directedToward = e.target.value; props.onChange(props.assessment)}}/>
                </div>
              </div>

              <div className="flex-row section">
                <div className="flex-row flex-justify-space-between">
                  <div className="flex-col ">
                    <h3>Witnesses</h3>   
                  </div>
                  <div className="flex-col flex-no-grow">
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={!props.assessment.data.witnesses?.length} onChange={(e) => { if (e.target.checked) { props.assessment.data.witnesses = [] } else { props.assessment.data.witnesses = [{}]; } props.onChange(props.assessment)}} />} label="No Witness" />
                    </FormGroup>
                  </div>
                </div>                    
                { props.assessment.data.witnesses?.length ?
                <div>
                  { props.assessment.data.witnesses.map( (witness, index) => {
                      return (
                      <div className="flex-row" key={index}>
                          <div className='flex-row'>
                            <div className="flex-col">
                                <TextField type="text" maxLength="100" label="Name" required value={witness.name || ''} onChange={ (e) => { props.assessment.data.witnesses[index].name = e.target.value; props.onChange(props.assessment) }} />           
                            </div>
                            <div className="flex-col">
                                <TextField type="text" maxLength="20" label="Contact Information" required value={witness.contactInfo || ''} onChange={ (e) => { props.assessment.data.witnesses[index].contactInfo = e.target.value; props.onChange(props.assessment) }} />           
                            </div>
                            <div className="flex-col">
                                <TextField type="text" maxLength="40" label="Relationship to Student (Other Student, Teacher, Bus Driver, Etc.)" required value={witness.relationship || ''} onChange={ (e) => { props.assessment.data.witnesses[index].relationship = e.target.value; props.onChange(props.assessment) }} />           
                            </div>
                          </div>
                          { index+1 === props.assessment.data.witnesses.length ?
                          <div className="add-another">
                              <span className='addAnotherOther' onClick={() => { props.assessment.data.witnesses.push({}); props.onChange(props.assessment)}}><i className="fa fa-plus"> Add Another</i></span>
                          </div>
                          : null }
                      </div>
                      )
                  })}
                </div>
                : null }
              </div>             


                <div className="flex-row ">
                  <div className="flex-col">
                    <TextField label="Write a Brief Narrative Explaining the Reported Concern" multiline maxRows={5} type="text"  inputProps={{ maxLength: 8000}}  maxLength="8000" value={props.assessment.comments || ''} required onChange={(e) => { props.assessment.comments = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                </div>
                </Paper>




                <Paper className='paper'>
                  <div>
                    Was the matter reported to the target of the threat's parents?
                  </div>
                  <div className="flex-row">                
                      <div className="flex-col">
                          <FormControl component="fieldset">
                              <RadioGroup row>
                                  <FormControlLabel label="Yes" control={<Radio required />} checked={ !!props.assessment.data.directedTowardParentNotified } type="radio" required onChange={() => { props.assessment.data.directedTowardParentNotified = true; props.onChange(props.assessment) }  }/>
                                  <FormControlLabel label="No" control={<Radio required />} checked={ props.assessment.data.directedTowardParentNotified === false } type="radio" required onChange={() => { props.assessment.data.directedTowardParentNotified = false; props.onChange(props.assessment) } }/>
                              </RadioGroup>
                          </FormControl>
                      </div>
                  </div>

                  { props.assessment.data.directedTowardParentNotified !== undefined ?
                    <div>
                    { !!props.assessment.data.directedTowardParentNotified ?
                      <div className="flex-row ">
                        <div className="flex-col">
                          <TextField label="Method of Notification" type="text" maxLength="50" value={props.assessment.data.directedTowardParentNotifiedMethod || ''} required onChange={(e) => { props.assessment.data.directedTowardParentNotifiedMethod = e.target.value; props.onChange(props.assessment)}}/>
                        </div>
                        <div className="flex-col">                            
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker renderInput={(props) => <TextField {...props} />} label="Notification Date" value={props.assessment.data.directedTowardParentNotifiedDate ? moment.utc(props.assessment.data.directedTowardParentNotifiedDate).local() : null } onChange={(e) => {if (!e) { return };  props.assessment.data.directedTowardParentNotifiedDate = e.format(); props.onChange(props.assessment)}}/>
                          </LocalizationProvider>
                          </div>
                      </div>
                    :
                      <div className="flex-row ">
                        <div className="flex-col">
                          <TextField label="Why Was the Parent Not Notified" multiline maxRows={5} type="text"  inputProps={{ maxLength: 1000}}  maxLength="1000" value={props.assessment.data.directedTowardParentNotNotifiedReason || ''} required onChange={(e) => { props.assessment.data.directedTowardParentNotNotifiedReason = e.target.value; props.onChange(props.assessment)}}/>
                        </div>
                      </div>
                    }
                    </div>
                  : null }

                  <div className='section'>
                  "Reasonable effort to notify" means the exercise of reasonable diligence and care to make contact with the targeted
student's parent or guardian, typically through the contact information shared by the parent or guardian with the school
or school district. The SBTMT Chair or designee must document all attempts to make contact with the parent or
guardian
                  </div>

                </Paper>

                <Paper className="paper">
                  <h3>HPTM Reviewers</h3>   
                  { reviewers.map( (requestReviewWithItem, index) => {
                    return (
                      <div key={index} className="flex-row">
                          <div className="flex-col">
                              <span>{requestReviewWithItem.nameText}</span>
                          </div>
                      </div>
                    )
                  })}
                </Paper>


                <Paper className="paper">
                <div className="flex-row">
                    <div className="flex-col">
                      Through a preliminary review of information known, the SBTMT chair must assess whether a factual basis exists for the claim as soon as possible, but within one school day of receiving the information.
                    </div>
                </div>
                </Paper>

 
    </div>
  )
}
