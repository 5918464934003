import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment'
import * as Sentry from '@sentry/browser';
import { Paper } from '@mui/material';

import threatAssessmentService from '../../services/threatAssessment';
import AppContext from '../../context/app'


export default function Detail(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()

  const [assessment, setAssessment] = useState(null)
  const [assessmentModel, setAssessmentModel] = useState(null)
  const [notificationAssets, setNotificationAssets] = useState([])
  const [showSupplemental, setShowSupplemental] = useState(false)

  const processInterview = (assessment, assessmentModel) => {
    //you are the user these are the ones that are requested of you (do not show already completed and needed only 1)      
    let usersRequestedInterviews = assessmentModel.interviewTypes.filter( (it) => { return (it.config.autoAssignTo === 'all' || assessment.assigned.find( a => a.role === it.role )) && !(it.config.oneNeeded && assessment.interviewers.find( i => i.role === it.role) )})
    assessment.supplementalRequested = usersRequestedInterviews.filter( (assigned) => { return assigned.config.isSupplemental } )
    assessment.requiredRequested = usersRequestedInterviews.filter( (assigned) => { return !assigned.config.isSupplemental } )        
    return assessment
  }

  const processSupplemental = (assessment) => {
    //if high risk, supplemental is required if not already complete
    if (assessment.isOpen && assessment.typeName === 'Threat Assessment' && assessment.threatLevelClassification === 'Extremely High Risk')
        setShowSupplemental(true)
  }

  useEffect(() => {
    const loadAssessment = async () => {
      window.scroll(0,0)
      try {
        let loadedAssessment = await new threatAssessmentService().get(threatAssessmentId)
        if (loadedAssessment && loadedAssessment.error)
          return

        let assessmentModel = await new threatAssessmentService().getAssessmentModelByAssessmentId(loadedAssessment.threatAssessmentId)
        let assignableInterviewRoles = assessmentModel.interviewTypes.filter( it => !it.config.notAssignable && !['all','creator'].includes(it.config.autoAssignTo)).map( ai => ai.role)
        loadedAssessment.interviewsNeedAssignment = assignableInterviewRoles.length && !!assignableInterviewRoles.find( r => !loadedAssessment.assigned.find( a => a.role === r ) )
        setAssessmentModel(assessmentModel)

        loadedAssessment = processInterview(loadedAssessment, assessmentModel)        
        processSupplemental(loadedAssessment)

        setAssessment(loadedAssessment)
      }
      catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
      }
    }
    loadAssessment()
  },[threatAssessmentId])

  //assessment specific
  useEffect(() => {
    const loadNotificationAssets = async () => {
      try {
        if (assessment && assessment.config.resources?.filter( r => r.isNotify ).length) {
          let assetNames = await new threatAssessmentService().getAssetNames(threatAssessmentId, 'notification')
          setNotificationAssets(assetNames)
        }
      }
      catch(err){
        console.log(err)
        context.toastError('Loading Notifications')
        Sentry.captureException(err);
      }
    }
    loadNotificationAssets()
  },[assessment])

  return (
    <div>
      <div id='threatAssessment'>
      
      { assessment && assessmentModel ?
          <section>
             { !assessment.isOpen ?
                <div className="case-closed">
                    Archived on {moment(assessment.closeDate).format('MM/DD/YYYY')}
                </div>
            : null }  


            { assessment.reviews?.length ? 
                <Paper className="paper">
                    <h2>Review</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                            { assessment.reviews.map( ( review, index) => {
                            return (
                                <div className="review" key={index}>
                                    { review.userFirstName ? `${review.userFirstName} ${review.userLastName}` : review.userEmail } ({review.userTitle}) - { review.isApproval ? 'Concur' : 'Request Further Review'}
                                    { review.comment ?
                                        <span> - "{review.comment}"</span>
                                    : null }
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </Paper>
            : null }

              <Paper className="paper columns">
                <h2>Summary</h2>

                {/* { assessment.synthasisSummary ?
                <div className='section'>
                    <span className="ai-generated-label">AI Generated</span> {assessment.synthasisSummary}
                    <div className="ai-generated-warning">
                        Summary generated by AI, please review all information.
                    </div>
                </div>
                : null } */}
                
                { assessment.resolutionClassification || (assessment.threatLevelClassification && assessment.threatLevelClassification !== 'NA') ?
                <div className="flex-row">
                    <div className="flex-col">
                        <label>Classification:</label> { assessment.resolutionClassification || assessment.threatLevelClassification }
                    </div>
                </div>
                : null }

                <div className="flex-row">
                    <div className="flex-col">
                        <label>Type:</label> {assessment.typeName}
                    </div>
                </div>

                <div className='flex-row'>
                    <div className="flex-col">
                        <label>Institution:</label> {assessment.institutionName}
                    </div>
                    <div className="flex-col">
                        <label>Building:</label> {assessment.buildingName}
                    </div>
                </div>

                <div className="flex-row">
                    <div className="flex-col">
                        <label>Case Created:</label> {moment(assessment.createDate).format('MM/DD/YYYY')} by {assessment.createdByFirstName} {assessment.createdByLastName}
                    </div>

                    {assessment.threatMadeDate ? 
                    <div className="flex-col">
                        <label>Incident Date:</label> {moment.utc(assessment.threatMadeDate).local().format('MM/DD/YYYY hh:mm a')}
                    </div>
                    : null }
                </div>
                <div className="flex-row">
                    <div className="flex-col">
                        <label>Brought to our attention:</label>
                        {assessment.reason}: {assessment.reportedByName}
                    </div>

                    { assessment.reportReceivedBy ?
                        <div className="flex-col">
                            <label>Name of person receiving the report:</label>
                            {assessment.reason}: {assessment.reportReceivedBy}
                        </div>
                    : null }
                </div>

                <div className="flex-row full-row">
                    <div className="flex-col comments">
                        <label>Summary of the incident or threat:</label>
                        {assessment.threatLocation ?
                            <>{assessment.threatLocation}: </>
                        : null }
                        {assessment.comments}
                    </div>
                </div>
            </Paper>              

            <Paper className="paper columns">
                <h2>Subject of Concern</h2>
                { assessment.gender || assessment.grade || assessment.race ?
                <div className="flex-row">
                   { assessment.gender ?
                    <div className="flex-col">
                        <label>Identification:</label> {assessment.gender}
                    </div>
                    : null }

                  { assessment.race ?
                    <div className="flex-col">
                        <label>Race:</label> {assessment.race }
                    </div>
                  : null }

                  { assessment.grade ?
                    <div className="flex-col">
                        <label>Grade:</label> {assessment.grade }
                    </div>
                  : null }
                </div>
                : null }

                <div className="flex-row">
                { assessment.dateOfBirth  ?
                <div className="flex-col">
                    <label>Date of Birth:</label> { moment(assessment.dateOfBirth).format('MM/DD/YYYY') }
                </div>
                : null }

                  {assessment.age ?
                    <div className="flex-col">
                        <label>Age:</label> {assessment.age}
                    </div>
                  : null }
                </div>

              { assessment.assessedHomeAddress ||  assessment.assessedPhone ? 
                  <div className="flex-row">
                      <div className="flex-col">
                          <label>Home Address:</label> {assessment.assessedHomeAddress}
                      </div>

                      <div className="flex-col">
                          <label>Phone:</label> {assessment.assessedPhone}
                      </div>
                  </div>
              : null }

              { assessment.threatPersonType ?
                <div className="flex-row">
                  <div className="flex-col">
                      <label>Affiliation of person making threat:</label>
                      { assessment.isCurrentThreatPersonType === null ?
                          <span className="isCurrentThreatPersonType">
                              { assessment.isCurrentThreatPersonType ?
                                  <span>(Current)</span>
                              :
                                  <span>(Former)</span>
                              }
                          </span>
                      : null }
                      {assessment.threatPersonType}
                  </div>  
                  { assessment.schoolProgram ?
                  <div className="flex-col">
                      <label>School Program:</label> {assessment.schoolProgram }
                  </div>
                  : null }                  
                </div>
                : null }

              { assessment.emergencyContactName ?
                <div className="flex-row">
                  <div className="flex-col">
                      <label>Emergency Contact:</label> {assessment.emergencyContactName} ({assessment.emergencyContactRelationship})
                  </div>
              </div>
              : null }
        </Paper>

            { assessment.findings && assessment.findings.filter( f => f.reviewed ).length ? 
                <Paper className="paper">
                    <h2>Assessment Findings</h2>
                    <div className="flex-row finding-row">                          
                        <div className="flex-col findings">
                        { assessment.findings.map( (finding, index) => {
                            return(
                                <div className={`finding`} key={index}>
                                    <span className="finding-item informationSource"><label>{finding.informationSource}:</label></span>
                                    <span className="finding-item reviewed">{finding.reviewed}</span>
                                    <span className="finding-item">{finding.comments}</span>
                                </div>
                            )
                        })
                        }
                    </div>
                    </div>
                </Paper>
            : null }

            <div>
                  { assessment.interviewers.length ?
                      <div className="interviews">
                          <Paper className="paper">
                            <h2>Interviews Completed</h2>
                            <div className='flex-row'>
                                <div className='flex-col'>
                                    {
                                        assessment.interviewers.map( (interviewer, index) => {
                                            return (
                                                <div className="interview" key={index}>
                                                    <span >
                                                        { interviewer.firstName ?
                                                            <span>{ interviewer.firstName } { interviewer.lastName } </span>
                                                        :
                                                            <span>{ interviewer.email } </span>
                                                        }
                                                        | <span>{assessmentModel.interviewTypes.find( a => a.role === interviewer.role ).config.interviewText} </span> 
                                                        | <span>{moment(interviewer.createDate).format('MM/DD/YYYY')}</span>
                                                    </span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                          </Paper>
                      </div>
                  :
                      null
                  }
              </div>

            <div>
                  { assessment.assigned.length ?
                      <div className="interviews">
                          <Paper className="paper">
                            <h2>Interviews Assignments</h2>
                            <div className='flex-row'>
                                <div className='flex-col'>
                                    {
                                        assessment.assigned.map( (assignment, index) => {
                                            return (
                                                <div className="interview" key={index}>
                                                    <span >
                                                        { assignment.firstName ?
                                                            <span>{ assignment.firstName } { assignment.lastName } </span>
                                                        :
                                                            <span>{ assignment.email } </span>
                                                        }
                                                        | <span>{assessmentModel.interviewTypes.find( a => a.role === assignment.role ).config.interviewText} </span> 
                                                    </span>
                                                </div>
                                            );
                                        })
                                    }

                                    { showSupplemental ?
                                    <div>
                                        { assessment.supplementalRequested.map( (assignment, index) => {
                                            return (
                                                <div className="interview" key={index}>
                                                    <span >
                                                        { assignment.firstName ?
                                                            <span>{ assignment.firstName } { assignment.lastName } </span>
                                                        :
                                                            <span>{ assignment.email } </span>
                                                        }
                                                        | <span>{assignment.config.interviewText} </span> 
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : null }
                                </div>
                            </div>
                            { assessment.additionalParticipants.map( (additionalParticipant, index) => {
                                return (
                                    <div className="interview" key={index}>
                                        <span >
                                            <span>{ additionalParticipant.name } </span>
                                            | <span>{additionalParticipant.role} </span> 
                                        </span>
                                    </div>
                                );
                            })}
                          </Paper>
                      </div>
                  :
                      null
                  }
              </div>

              <div>
                  {   assessment.related.length ?
                      <div className="related-assessments">
                          <Paper className="paper">
                             <h2>Related Cases</h2>
                            <div className='flex-row'>
                                <div className='flex-col'>
                                {
                                    assessment.related.map( (relatedAssessment, index) => {
                                        return (
                                            <Link key={index} to={`/assessment/detail/${relatedAssessment.relatedThreatAssessmentId}`}>
                                                <div className="related-assessment" >
                                                    {relatedAssessment.assessedFirstName} {relatedAssessment.assessedLastName} ({relatedAssessment.typeName})
                                                </div>
                                            </Link>
                                        );
                                    })
                                }
                                </div>
                            </div>
                          </Paper>
                      </div>
                  :
                      null
                  }
              </div>

              { notificationAssets.length ?
                  <div className="files">
                      <Paper className="paper">
                        <h2>Acknowledgements:</h2>
                        <div className='flex-row'>
                            <div className='flex-col'>
                                <div className="flex-row">
                                    { notificationAssets.map( (fileName, index) => {
                                        return(
                                        <div className="flex-col uploaded-file-container" key={index}>
                                            <span 
                                                className="fa fa-file uploaded-file"
                                                alt={fileName.fileName  }
                                                title={fileName.fileName }
                                                onClick={handleDownloadFile}
                                            />

                                            { fileName.signature ?
                                                <i className="fa fa-check delete-image" title="Acknowledged"></i>
                                            : null }
                                            <div className="uploaded-file-caption">
                                                {fileName.fileName.substring(10,fileName.fileName.length)}
                                            </div>
                                        </div>
                                        )
                                        }) 
                                    }
                                </div>
                            </div>
                        </div>
                      </Paper>
                  </div>
              : null }
          </section>
      :
          null
      }
      </div>
      </div>
  )
}