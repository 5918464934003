import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../context/app'
import Loader from '../../../components/Loader'
import * as Sentry from '@sentry/browser';
import threatAssessmentService from '../../../services/threatAssessment';

import AssessmentList from '../../../components/AssessmentList'

export default function List() {
  const context = useContext(AppContext)
  const [assessments, setAssessments] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadAssessments = async () => {
      window.scroll(0,0)
      try {
        const assessments = await new threatAssessmentService().getToReview()
        setAssessments(assessments)
        setLoading(false)
      }
      catch(err) {
        console.log(err)
        Sentry.captureException(err)
      }
    }
    loadAssessments()
  }, [])

  return (
    <div id='threatAssessments'>
         { !loading  ?
            <div>
              <AssessmentList assessments={assessments} ></AssessmentList>
            </div>
          : <Loader/> }
    </div>
  )
}