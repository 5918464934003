import React, { useEffect, useContext, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, MenuItem, FormControl, RadioGroup, Radio, FormControlLabel} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import AppContext from '../../../context/app'
import OtherDropDown from '../../../components/OtherDropDown';
import threatAssessmentService from '../../../services/threatAssessment';
import Loader from '../../../components/Loader';


export default function Resolve(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()
  const [assessment, setAssessment] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    null
  );
  const [resolution, setResolution] = useState({ data: {} })
  const [assessmentModel, setAssessmentModel] = useState(null)
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    const loadAssessment = async () => {
      window.scroll(0,0)

      try {
        //load all data needed
        let threatAssessment = await new threatAssessmentService().get(threatAssessmentId)
        if (threatAssessment && threatAssessment.error){
          setLoading(false)
          return
        }

        if (threatAssessment.resolution){
          threatAssessment.resolution.data = threatAssessment.resolution.data || {}
          if (threatAssessment.resolution.resolutionFollowUpDate)
            threatAssessment.resolution.resolutionFollowUpDate = moment(threatAssessment.resolution.resolutionFollowUpDate)
          setResolution(threatAssessment.resolution)
        }
        setAssessment(threatAssessment)

        let loadedAssessmentModel = await new threatAssessmentService().getAssessmentModelByAssessmentId(threatAssessment.threatAssessmentId)
        setAssessmentModel(loadedAssessmentModel)
        
        setLoading(false)
      }
      catch(err) {
        console.log(err)
        Sentry.captureException(err)
      }
    }
    loadAssessment()
  }, [threatAssessmentId])

  const handleSave = async (event) => {
    event.preventDefault();
    try {
        let resolutionUpdate = { threatAssessmentId, resolutionFollowUpDate: resolution.resolutionFollowUpDate, resolutionNotes: resolution.resolutionNotes, data: resolution.data };
        await new threatAssessmentService().resolve(resolutionUpdate)
        context.toastSuccess('Notes saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Notes could not save')
      Sentry.captureException(err);
    }
  }

    if(loading && !assessment)
      return (<Loader/>)

    if (assessment && assessmentModel?.interviewTypes.length && !assessment.interviewers.length && assessment.isOpen)
      return (
        <Paper className="paper">
          Please complete interviews before creating an action plan
        </Paper>
      )

    if (assessment)
      return ( 
        <div id='threatAssessmentResolve'>
          <form onSubmit={handleSave}>
            <Paper className="paper">
                <h2>Case Resolution or Safety Plan</h2>
                <div className='flex-row section'>
                    {assessment.resolutionClassification ?
                        <div className='flex-col'>
                            <label>Classification:</label> {assessment.resolutionClassification}
                        </div>
                    : null }
                </div>
                
               <div className='flex-row section'>
                    <div className='flex-col full-width'>
                        <TextField multiline maxRows={10} inputProps={{ maxLength: 12000}} maxLength="12000" label="Case Resolution or Safety Plan" value={resolution?.resolutionNotes || ''}  onChange={ (e) => { resolution.resolutionNotes = e.target.value; setResolution({...resolution}) } }/> 
                    </div>
                </div>

              <div className="section">
                  Was the Subject of Concern Referred to Services?
              </div>
              <div className="flex-row">                
                <div className="flex-col">
                  <FormControl component="fieldset">
                    <RadioGroup row>
                      <FormControlLabel label="Yes" control={<Radio required />} checked={resolution.data.isReferredToServices === true} type="radio" required onChange={(e) => { resolution.data.isReferredToServices = true; setResolution({...resolution}) } }/>
                      <FormControlLabel label="No" control={<Radio required />} checked={resolution.data.isReferredToServices === false} type="radio" required onChange={(e) => { resolution.data.isReferredToServices = false; setResolution({...resolution}) } }/>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col full-width">
                  <TextField fullWidth="true" label="Comments" multiline maxRows={2} type="text"  inputProps={{ maxLength: 2000}}  maxLength="2000" value={resolution.data.referredToServicesComments || ''} required onChange={(e) => { resolution.data.referredToServicesComments = e.target.value; setResolution({...resolution})}}/>
                </div>
              </div>


              <div className="section">
                 Were Parents Notified?
              </div>
              <div className="flex-row">                
                <div className="flex-col">
                  <FormControl component="fieldset">
                    <RadioGroup row>
                      <FormControlLabel label="Yes" control={<Radio required />} checked={resolution.data.isParentNotified === true} type="radio" required onChange={(e) => { resolution.data.isParentNotified = true; setResolution({...resolution}) } }/>
                      <FormControlLabel label="No" control={<Radio required />} checked={resolution.data.isParentNotified === false} type="radio" required onChange={(e) => { resolution.data.isParentNotified = false; setResolution({...resolution}) } }/>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col  full-width">
                  <TextField fullWidth="true" label="Comments" multiline maxRows={2} type="text"  inputProps={{ maxLength: 2000}}  maxLength="2000" value={resolution.data.parentNotifiedComments || ''} required onChange={(e) => { resolution.data.parentNotifiedComments = e.target.value; setResolution({...resolution})}}/>
                </div>
              </div>

              <div className="section">
                 Was Administrator Notified?
              </div>
              <div className="flex-row">                
                <div className="flex-col">
                  <FormControl component="fieldset">
                    <RadioGroup row>
                      <FormControlLabel label="Yes" control={<Radio required />} checked={resolution.data.isAdministratorNotified === true} type="radio" required onChange={(e) => { resolution.data.isAdministratorNotified = true; setResolution({...resolution}) } }/>
                      <FormControlLabel label="No" control={<Radio required />} checked={resolution.data.isAdministratorNotified === false} type="radio" required onChange={(e) => { resolution.data.isAdministratorNotified = false; setResolution({...resolution}) } }/>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col full-width">
                  <TextField fullWidth="true" label="Comments" multiline maxRows={2} type="text"  inputProps={{ maxLength: 2000}}  maxLength="2000" value={resolution.data.adminstratorNotifiedComments || ''} required onChange={(e) => { resolution.data.adminstratorNotifiedComments = e.target.value; setResolution({...resolution})}}/>
                </div>
              </div>


              <div className="flex-row section">
                <div className="flex-col full-width">
                  <OtherDropDown fullWidth="true" label="Student Status" value={resolution.data.assessedStatus} required onChange={(e) => { resolution.data.assessedStatus = e.target.value; setResolution({...resolution})}} >
                      <MenuItem value="Picked Up By Parent">Picked Up By Parent</MenuItem>
                      <MenuItem value="Emergency Supports">Emergency Supports</MenuItem>
                      <MenuItem value="Returned To Class">Returned To Class</MenuItem>
                  </OtherDropDown>
                </div>
              </div>

              <div className='flex-row section'>
                  <div className='flex-col'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker disablePast={true} label="Remind Me to Follow Up" value={resolution?.resolutionFollowUpDate || null} onChange={(e) => { resolution.resolutionFollowUpDate = e; setResolution({...resolution}) }} renderInput={(props) => <TextField {...props} />}/>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="section">
                    <Button variant="outlined" type="submit">
                      <Icon className="fa fa-save" />Save
                    </Button>
                  </div>
            </Paper>
          </form>
        </div>
      )
}