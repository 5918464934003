import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField} from '@mui/material';

import threatAssessmentService from '../../../services/threatAssessment';
import AppContext from '../../../context/app'


export default function Review(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()

  const [assessment, setAssessment] = useState(null)
  const [reviewRequest, setReviewRequest] = useState(null)
  const [comment, setComment] = useState()


  useEffect(() => {
    const loadAssessment = async () => {
      window.scroll(0,0)
      try {
        let loadedAssessment = await new threatAssessmentService().get(threatAssessmentId)
        if (loadedAssessment && loadedAssessment.error)
          return

        //get the latest review request
        setAssessment(loadedAssessment) 
        
        let reviewRequests = loadedAssessment.reviewRequests
        let userReviewRequest = reviewRequests.find(r => r.userId === context.user.userId)
        setReviewRequest(userReviewRequest)

        let userComment = loadedAssessment.reviews.find(r => r.userId === context.user.userId)
        if (userComment)
          setComment(userComment.comment)
      }
      catch(err) {
        console.log(err)
        Sentry.captureException(err)
      }
    }
    loadAssessment()
  }, [threatAssessmentId])

  const approve = async (e) => {
    try {
      await new threatAssessmentService().reviewApprove(threatAssessmentId, comment, reviewRequest?.subtype || 'review')
      context.toastSuccess('Review saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  const reject = async (e) => {
    try {
      await new threatAssessmentService().reviewReject(threatAssessmentId, comment, reviewRequest?.subtype || 'review')
      context.toastSuccess('Review saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div id='threatAssessmentReview'>

    { assessment ?
        <section>
            { context.user.reviewThreats ?
                <section>
                    <Paper className="paper">
                        <div className="flex-row">
                            <div className="flex-col">
                                <TextField multiline maxRows={5}  inputProps={{ maxLength: 2000}}  maxLength="2000" label="Comment" value={comment || ''}  onChange={ (e) => { setComment(e.target.value); } }/> 
                            </div>
                        </div>
                    </Paper>

                    <div className="flex-row actions">
                        <div className="flex-col action">
                            <Button variant="outlined" onClick={approve}>
                            <Icon className="fa fa-check" />Concur
                            </Button>
                        </div>

                        <div className="flex-col action">
                            <Button variant="outlined" onClick={reject}>
                            <Icon className="fa fa-search" />Request Further Review
                            </Button>
                        </div>
                    </div>
                </section>
            : null }
        </section>
    : null }
    </div>
  )
}