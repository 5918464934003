import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { TextField, MenuItem } from '@mui/material';
import userService from '../../../services/user'


InterviewAdditionalInterviewer.propTypes = {
    assessmentModel: PropTypes.object,
    assessment: PropTypes.object,
    interview: PropTypes.object,
    onChange: PropTypes.func
};

export default function InterviewAdditionalInterviewer(props) {
    const [show, setShow] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        const loadUsers = async () => {
            let load = !!props.interview.config?.interviewModules?.includes('additionalInterviewer')
            setShow(load)

            if (load) {
                let users = await new userService().getInstitutionBuildingUsers(props.assessment.institutionBuildingId)
                users = users.map( (user) => {
                    user.nameText = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
                    return user
                })
                users = users.filter( (user) => { return !props.assessmentModel?.config?.assignableToPermissions || props.assessmentModel.config.assignableToPermissions.find(p => user[p] )} )
                users = users.sort( (a, b) => { return (a.lastName + a.firstName) - (b.lastName + b.firstName)  } )
                setUsers(users)
            }
        }
        loadUsers()
    }, [props.interview.config, props.assessment.institutionBuildingId])

    if (!show)
        return null

    return (
        <div className="section">
            <TextField label="Additional Interviewer" select value={props.interview.data?.additionalInterviewers ? props.interview.data.additionalInterviewers[0] : ''} onChange={ (e) => props.onChange([e.target.value])}>           
                { users.map( (user, index) => {
                    return(
                    <MenuItem key={index} value={user.userId}>{user.nameText}</MenuItem>
                    )
                }) 
                }
            </TextField>
        </div>
    )
}