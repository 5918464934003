import React, { useState, useEffect } from 'react';

export default function AnswerTally(props) {
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const initialize = async () => {
      let threatAssessmentQuestions = props.threatAssessmentQuestions
      threatAssessmentQuestions.forEach( (question) => {
        question.answerTally = [];
        question.yesAnswers = [];

        if (props.threatAssessment.interviewers) {
          props.threatAssessment.interviewers.forEach( (interview) => {
            interview.questionSections.forEach( (questionSection) => {
              let questionAnswer = questionSection.questions.find( (interviewQuestion) => { return question.threatAssessmentInterviewQuestionId === interviewQuestion.threatAssessmentInterviewQuestionId })
              if (!questionAnswer)
                return;

              questionAnswer.answers.forEach( (answer) => {
                if (props.yesOnly && (answer.isYes === null || answer.isYes)) {
                  answer.comments = questionAnswer.comments
                  question.yesAnswers.push(answer);
                }

                if (answer.isYes)
                  question.answerTally.push(true);

              })

              if (props.commentsOnly && (questionAnswer.comments && questionAnswer.comments.length)){
                question.comments.push(questionAnswer.comments);
              }
            })
          });
        }
      })
      setQuestions(threatAssessmentQuestions)
    }
    initialize()
  },[])

  return (
      <ul className="answerTally">
      { questions.map( (threatAssessmentQuestion, index) => {
              return (
                  <li className='rows threatAssessmentQuestion' key={threatAssessmentQuestion.threatAssessmentInterviewQuestionId} >
                      <div>
                          <span className="threatAssessmentQuestionText">Q{index+1}: {threatAssessmentQuestion.text}</span>

                          { 
                            threatAssessmentQuestion.answerTally.map( (yesAnswer, index) => {
                              return(
                                <span key={index}>
                                  <i className="fa fa-check"></i>
                                </span>
                              )
                            })
                          }
                      </div>

                      <div>
                          <ul className="overview-comments">
                            {   threatAssessmentQuestion.yesAnswers.map( (yesAnswer, index) => {
                                return(
                                    <li className="overview-comment" key={index}>
                                        { yesAnswer.text }
                                        
                                        { yesAnswer.comments && yesAnswer.comments.comment ?
                                          <span>                                            
                                              <span>
                                              &nbsp;-&nbsp;
                                              </span>
                                            {yesAnswer.comments.comment}
                                          </span>
                                        : null }
                                    </li>
                                )
                                })
                            }
                          </ul>
                      </div>
                      
                  </li>
              )
          }
      )}
      </ul>
  )
}
