import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Checkbox, Button, TextField, FormGroup, FormControlLabel } from '@mui/material';

import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';

import formConfig from '../../../config/Community/ReferralOptions.json'

const formId = 8

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [safetyPlanAndMitigationChoices, setSafetyPlanAndMitigationChoices] = useState(formConfig.safetyPlanAndMitigationChoices.map(c => { return { choice: c }}))
    const [otherSafetyPlanAndMitigation, setOtherSafetyPlanAndMitigation] = useState([])
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: { } }
    );

    useEffect(() => {
        const loadForm = async () => {
            if (props.editForm) {
                if (props.editForm.data.safetyPlanAndMitigation){
                    let otherChoices = props.editForm.data.safetyPlanAndMitigation.filter(item => !formConfig.safetyPlanAndMitigationChoices.includes(item))
                    setOtherSafetyPlanAndMitigation(otherChoices)
                    //keep what is not other
                    props.editForm.data.safetyPlanAndMitigation = props.editForm.data.safetyPlanAndMitigation.filter(item => formConfig.safetyPlanAndMitigationChoices.includes(item))
                }
                setForm(JSON.parse(JSON.stringify(props.editForm)))
            }
            setLoading(false)
        }
        loadForm()
    }, [])


    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (otherSafetyPlanAndMitigation.length)
                form.data.safetyPlanAndMitigation = form.data.safetyPlanAndMitigation.concat(otherSafetyPlanAndMitigation)
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({ pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch (err) {
            console.log(err)
            context.toastError('Form could not be saved')
            Sentry.captureException(err);
        }
    }

    const handleChoice = (propertyName, choiceValue, isChecked) => {
        if (!form.data[propertyName])
            form.data[propertyName] = []
        if (isChecked) 
            form.data[propertyName].push(choiceValue); 
        else
            form.data[propertyName] = form.data[propertyName].filter( o => o !== choiceValue )
        setForm({...form})
    }

    const handleChoiceWithData = (propertyName, choiceValue, isChecked) => {
        if (!form.data[propertyName])
            form.data[propertyName] = []
        if (isChecked) 
            form.data[propertyName].push({ choice: choiceValue}); 
        else
            form.data[propertyName] = form.data[propertyName].filter( o => o.choice !== choiceValue )        
        setForm({...form})
     }
 


    return (
        <div id="assessmentForm">
            <form onSubmit={handleSave}>
                <Paper className="paper">
                    <h2>Person Of Concern</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label="Home Address" type="text" maxLength="320" value={form.data?.assessedHomeAddress || ''} required onChange={(e) => { form.data.assessedHomeAddress = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                            <TextField label="Phone" type="text" maxLength="15" value={form.data?.assessedPhone || ''} required onChange={(e) => { form.data.assessedPhone = e.target.value; setForm(form) }} />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                        <TextField label="Occupation" type="text" maxLength="45" value={form.data?.assessedOccupation || ''} required onChange={(e) => { form.data.assessedOccupation = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                        <TextField label="Business" type="text" maxLength="45" value={form.data?.assessedBusiness || ''} required onChange={(e) => { form.data.assessedBusiness = e.target.value; setForm(form) }} />
                        </div>
                    </div>   
                </Paper>

                <Paper className="paper">
                    <h2>Referral Source</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label="Name" type="text" maxLength="250" value={form.data?.referralName || ''} required onChange={(e) => { form.data.referralName = e.target.value; setForm(form) }} />
                        </div>
                        <div className="flex-col">
                            <TextField label="Agency" type="text" maxLength="45" value={form.data?.referralAgency || ''} required onChange={(e) => { form.data.referralAgency = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label="Phone" type="text" maxLength="15" value={form.data?.referralPhone || ''} required onChange={(e) => { form.data.referralPhone = e.target.value; setForm(form) }} />
                        </div>
                        <div className="flex-col">
                            <TextField label="Relationship" type="text" maxLength="45" value={form.data?.referralRelationship || ''} required onChange={(e) => { form.data.referralRelationship = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                </Paper>

                <Paper className="paper">
                    <h2>Person familiar with POC (parent, guardian, spouse, etc.)</h2>
                    <div className="flex-row">
                        <div className="flex-col">
                        <TextField label="Name" type="text" maxLength="100" value={form.data?.familiarToName || ''} required onChange={(e) => { form.data.familiarToName = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                        <TextField label="Phone" type="text" maxLength="15" value={form.data?.familiarToPhone || ''} required onChange={(e) => { form.data.familiarToPhone = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="flex-col">
                        <TextField label="Address" type="text" maxLength="320" value={form.data?.familiarToAddress || ''} required onChange={(e) => { form.data.familiarToAddress = e.target.value; setForm(form) }} />
                        </div>

                        <div className="flex-col">
                        <TextField label="Relationship" type="text" maxLength="45" value={form.data?.familiarToRelationship || ''} required onChange={(e) => { form.data.familiarToRelationship = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                </Paper>

                <Paper className="paper">
                    <h2>Targeted Violence Indicators</h2>
                    (Check all that apply)
                    <div className='flex-row choice-group'>
                    { formConfig.targetedViolenceIndicators.map( (section, index) => {
                        return(
                            <div className="flex-col section choice-column" key={index}>
                                <label>{section.name}</label>
                                <FormGroup aria-label="position">
                                    { section.choices.map( (choice, index) => {
                                        return (
                                            <div key={index}>
                                            <FormControlLabel key={index}
                                                control={<Checkbox
                                                        checked={form.data.targetedViolenceIndicators?.indexOf(choice) > -1}
                                                        onChange={(e) => { handleChoice('targetedViolenceIndicators',choice, e.target.checked)}}
                                                    />}
                                                    label={choice}
                                                    labelPlacement="end"
                                            />
                                            </div>
                                        )
                                    })}
                                </FormGroup>
                            </div>
                        )
                    })}
                    </div>                    
                </Paper>

                <Paper className="paper">
                    <h2>Pathway Behaviors</h2>
                    (Check all that apply)
                    <div className='flex-row choice-group'>
                    { formConfig.pathwayBehaviors.map( (section, index) => {
                        return(
                            <div className="flex-col section choice-column" key={index}>
                                <label>{section.name}</label>
                                <FormGroup  aria-label="position">
                                    { section.choices.map( (choice, index) => {
                                        return (
                                            <div key={index}>
                                            <FormControlLabel key={index}
                                                control={<Checkbox
                                                    checked={form.data.pathwayBehaviors?.indexOf(choice) > -1}
                                                    onChange={(e) => { handleChoice('pathwayBehaviors',choice, e.target.checked)}}
                                                />}
                                                    label={choice}
                                                    labelPlacement="end"
                                            />
                                            </div>
                                        )
                                    })}
                                </FormGroup>
                            </div>
                        )
                    })}   
                    </div> 
                </Paper>

                <Paper className="paper">
                    <h2>Risk Assessment</h2>
                    <div className='flex-row choice-group'>
                        <div className="flex-col section choice-column">
                            <label>Referrals Made</label>
                            <FormGroup  aria-label="position">
                                { formConfig.riskAssessmentReferralsChoices.map( (choice, index) => {
                                    return (
                                        <div key={index}>
                                            <FormControlLabel key={index}
                                                control={
                                                    <Checkbox 
                                                        checked={!!form.data?.riskAssessmentReferrals?.find( r => r.choice === choice)}
                                                        onChange={(e) => { handleChoiceWithData('riskAssessmentReferrals',choice, e.target.checked)}}
                                                    />}
                                                    label={choice}
                                                    labelPlacement="end"
                                            />
                                            { form.data?.riskAssessmentReferrals?.find( r => r.choice === choice) ?
                                                <div>
                                                    <TextField label="Name" type="text" maxLength="250" value={form.data?.riskAssessmentReferrals.find( r => r.choice === choice)?.name || ''} onChange={(e) => { let item = form.data?.riskAssessmentReferrals.find( r => r.choice === choice); item.name = e.target.value; setForm(form) }} />
                                                    <div className="section">
                                                        <TextField label="Contact Info" type="text" maxLength="100" value={form.data?.riskAssessmentReferrals.find( r => r.choice === choice)?.contactInfo || ''} onChange={(e) => { let item = form.data?.riskAssessmentReferrals.find( r => r.choice === choice); item.contactInfo = e.target.value; setForm(form) }} />
                                                    </div>
                                                </div>
                                            : null }
                                            </div>
                                    )
                                })}
                            </FormGroup>
                        </div>

                        <div className="flex-col section choice-column">
                            <label>Safety Plan / Mitigation options:</label>

                            <FormGroup  aria-label="position">
                                { safetyPlanAndMitigationChoices.map( (choice, index) => {
                                    return (
                                        <div key={index}>
                                            <FormControlLabel key={index}
                                                control={
                                                    <Checkbox
                                                        checked={!!form.data?.safetyPlanAndMitigation?.find( r => r === choice.choice)}
                                                        onChange={(e) => { handleChoice('safetyPlanAndMitigation',choice.choice, e.target.checked)}}
                                                    />}
                                                    label={choice.choice}
                                                    labelPlacement="end"
                                            />
                                        </div>
                                    )
                                })}
                                { otherSafetyPlanAndMitigation.map( (otherChoice, index) => {
                                    return (
                                        <div className='section' key={index}>
                                        <TextField label="Other" type="text" maxLength="100" value={otherChoice || ''} onChange={(e) => { otherSafetyPlanAndMitigation[index] = e.target.value;  setOtherSafetyPlanAndMitigation([...otherSafetyPlanAndMitigation]) }} />
                                        </div>
                                    )
                                })}
                                <div className='flex-row'>
                                 <span className='flex-col addAnotherOther' onClick={(e) => { otherSafetyPlanAndMitigation.push(''); setOtherSafetyPlanAndMitigation([...otherSafetyPlanAndMitigation]); }}><i className="fa fa-plus"></i> Add Another</span>
                                 {otherSafetyPlanAndMitigation.length ?
                                     <span className='flex-col addAnotherOther' onClick={() => { otherSafetyPlanAndMitigation.splice(otherSafetyPlanAndMitigation.length-1, 1); setOtherSafetyPlanAndMitigation([...otherSafetyPlanAndMitigation]); }}><i className="fa fa-minus"></i> Remove</span>
                                : null }
                                </div>
                                </FormGroup>
                        </div>
                    </div>
                </Paper>                

                <div>
                    <Button variant="outlined" type="submit">
                        <Icon className="fa fa-save" />Save
                    </Button>
                </div>
            </form>
        </div>
    )
}