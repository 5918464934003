import React, { useEffect, useContext, useState } from 'react';

import CommunityFollowUpItem from './CommunityFollowUpItem'
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../../components/Loader'
import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';
import { Collapse, Fab } from '@mui/material';
import moment from 'moment'

const formId = 7

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [addNew, setAddNew] = useState(false)
    const [forms, setForms] = useState([])
    
    useEffect(() => {
        const loadForms = async () => {
            let loadedforms = await new formService().getForms(props.threatAssessmentId,formId)
            loadedforms.forEach( lf => {
                lf.data.meetingDate = moment(lf.data.meetingDate)
                lf.data.monitoringEndDate = moment(lf.data.monitoringEndDate)
            })
            setForms(loadedforms)
            setLoading(false)
        }
        loadForms()
    }, [props.threatAssessmentId])

    if (loading)
        return (<Loader></Loader>)

    return (
    <div id="assessmentForms">
        { !addNew ?
            <Fab className="no-print" size="small" color="primary" aria-label="add" onClick={()=> { setAddNew(true)}}>
                <AddIcon /> 
            </Fab>
        : null }
        <Collapse in={addNew}>
            <CommunityFollowUpItem {...props} threatAssessmentId={props.threatAssessmentId} showFollowUpDate={true} />
        </Collapse>
        

        { forms.map( (form, index) => {
            return (
            <div key={index} className="flex-row">
                <CommunityFollowUpItem {...props} threatAssessmentId={props.threatAssessmentId} formAnswerId={form.formAnswerId} editForm={form} showFollowUpDate={!addNew && index === 0} />
            </div>
            )
        })}
    </div>
    )
}